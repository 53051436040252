import i18n from '~/translations';

export default async (axios, searchValue, page, limit, params = {}) => {
    const response = await axios.get('/v1/competitors/ranking/status');

    const { data } = response;

    return {
        metadata: {
            page: 1,
            pages: 1,
        },
        result: Object.keys(data.status).map(key => {
            const label = data.status[key] === 'none' ? i18n.t('VARIABLE_FILTER_LABELS:NOT_COMPETING')
                : i18n.t(`VARIABLE_FILTER_LABELS:${data.status[key].toUpperCase()}`);
            return {
                key,
                label,
            };
        }),
    };
};
