import { slugifyToURL } from '~/helpers/mask-helper';
import { MARKETPLACES_ID } from '~/values/enums';

const getCcTDL = (countryCode, marketplaceId) => {
    const ccTDL = {
        1: {
            BR: '.com.br',
            MX: '.com.mx',
        },
        2: {
            AR: '.com.ar',
            BO: '.com.bo',
            BR: '.com.br',
            CL: '.cl',
            CO: '.com.co',
            CR: '.co.cr',
            DO: '.com.do',
            EC: '.com.ec',
            GT: '.com.gt',
            HN: '.com.hn',
            MX: '.com.mx',
            NI: '.com.ni',
            PA: '.com.pa',
            PY: '.com.py',
            PE: '.com.pe',
            SV: '.com.sv',
            UY: '.com.uy',
            VE: '.com.ve',
        },
    };

    if (ccTDL[marketplaceId][countryCode] === undefined) {
        return '.com';
    }

    return ccTDL[marketplaceId][countryCode];
};

const makeURL = (title, adData) => {
    const {
        marketplaceId,
        skuMarketplace,
        countryCode,
        buyboxId,
    } = adData;

    if (marketplaceId) {
        if (MARKETPLACES_ID.AMAZON === marketplaceId) {
            return `https://www.amazon${getCcTDL(countryCode, marketplaceId)}/`
                .concat(slugifyToURL(title))
                .concat('/dp/')
                .concat(skuMarketplace);
        }

        if (MARKETPLACES_ID.MERCADO_LIVRE === marketplaceId) {
            let domain = 'produto.mercadolivre';
            if (countryCode !== 'BR') {
                domain = 'articulo.mercadolibre';
            }

            if (!buyboxId) {
                return `https://${domain}${getCcTDL(countryCode, marketplaceId)}/`
                    .concat(skuMarketplace.substring(0, 3)
                        .concat('-')
                        .concat(skuMarketplace.substring(3)))
                    .concat('-')
                    .concat(slugifyToURL(title));
            }

            domain = 'mercadolivre';

            return `https://${domain}${getCcTDL(countryCode, marketplaceId)}/${slugifyToURL(title)}/p/${buyboxId}`;
        }
    }
    return '';
};

export default makeURL;
