import React, {
    useState,
    useCallback,
    useMemo,
    memo,
    useImperativeHandle,
    forwardRef,
    useEffect,
    useRef,
} from 'react';

import { Empty, message } from 'antd';

import { wrapFormField } from '~/helpers/form-helper';
import useAxios from '~/hooks/use-axios';
import i18n from '~/translations';

import Input from '../Input';
import requestAccounts from './request-accounts';
import requestAdsStatus from './request-ads-status';
import requestAmazonAccounts from './request-amazon-accounts';
import requestBuyboxStatus from './request-buybox-status';
import requestCampaignStatus from './request-campaign-status';
import requestCampaigns from './request-campaigns';
import requestCities from './request-cities';
import requestCompetitorsName from './request-competitors-name';
import requestListingStatus from './request-listing-status';
import requestMarketPlace from './request-marketplace';
import requestMarketplaces from './request-marketplaces';
import requestRankingStatus from './request-ranking-status';
import requestRecipients from './request-recipients';
import requestRuleLogStatus from './request-rule-log-status';
import requestRuleRequiresConfirmation from './request-rule-requires-confirmation';
import requestRuleStatus from './request-rule-status';
import requestRuleStrategy from './request-rule-strategy';
import requestSellers from './request-sellers';
import requestSenders from './request-senders';
import requestShippingCompanies from './request-shipping-companies';
import requestShippingListStatuses from './request-shipping-list-statuses';
import requestStrategies from './request-strategies';
import requestTags from './request-tags';

const LIMIT = 15;
const INITIAL_PAGE = 0;

const useRequestByType = searchType => {
    return useMemo(() => {
        switch (searchType) {
            case 'request-tags':
                return requestTags;
            case 'request-marketplace':
                return requestMarketPlace;
            case 'shipping-list-statuses':
                return requestShippingListStatuses;
            case 'senders':
                return requestSenders;
            case 'recipients':
                return requestRecipients;
            case 'cities':
                return requestCities;
            case 'companies':
                return requestShippingCompanies;
            case 'strategies':
                return requestStrategies;
            case 'amazon-accounts':
                return requestAmazonAccounts;
            case 'campaigns':
                return requestCampaigns;

            // Dynamic Filter Buybox Monitoring Page
            case 'request-buybox-status':
                return requestBuyboxStatus;
            case 'request-marketplaces':
                return requestMarketplaces;
            case 'request-sellers':
                return requestSellers;
            case 'request-ads-status':
                return requestAdsStatus;
            case 'request-campaign-status':
                return requestCampaignStatus;
            case 'request-listing-status':
                return requestListingStatus;
            case 'request-rule-status':
                return requestRuleStatus;
            // Dynamic Filter Pricing Update History Page
            case 'request-rule-log-status':
                return requestRuleLogStatus;
            case 'request-rule-strategy':
                return requestRuleStrategy;
            case 'request-rule-requires-confirmation':
                return requestRuleRequiresConfirmation;
            case 'request-ranking-status':
                return requestRankingStatus;
            case 'request-accounts':
                return requestAccounts;
            case 'request-competitors-name':
                return requestCompetitorsName;

            default:
                return Promise.resolve;
        }
    }, [searchType]);
};

const requestOptions = async ({
    axios,
    requestSearch,
    searchValue,
    page,
    dataSource,
    params,
}) => {
    try {
        const response = await requestSearch(
            axios,
            searchValue,
            page,
            LIMIT,
            params,
        );
        const { result, metadata } = response;

        return {
            dataSource: [...dataSource, ...result],
            canLoadMore: metadata.page < metadata.pages - 1,
            page: metadata.page,
        };
    } catch (err) {
        console.warn(err);
        if (err.response?.status === 404) {
            return {
                dataSource: [],
                canLoadMore: false,
                page: INITIAL_PAGE,
            };
        }

        if (err.response) {
            message.error(
                i18n.t('INPUT_SEARCH:RESPONSE_ERROR'),
            );
        } else {
            message.error(
                i18n.t('INPUT_SEARCH:OFFLINE_ERROR'),
            );
        }
        throw err;
    }
};

const InputSearch = memo(
    forwardRef(
        (
            {
                input, params, searchType, onDropdownVisibleChange, baseUrl, ...others
            },
            ref,
        ) => {
            const axios = useAxios({
                baseURL: baseUrl || process.env.REACT_APP_URL_API,
            });

            const requestTimeout = useRef();
            const requestSearch = useRequestByType(searchType);

            const [fetching, setFetching] = useState(false);
            const [dataSource, setDataSource] = useState([]);
            const [canLoadMore, setCanLoadMore] = useState(false);
            const [page, setPage] = useState(0);
            const [searchValue, setSearchValue] = useState('');

            useImperativeHandle(
                ref,
                () => ({
                    clearOptions: () => {
                        setDataSource([]);
                        setCanLoadMore(false);
                        setPage(0);
                    },
                }),
                [],
            );

            useEffect(() => {
                return () => {
                    clearTimeout(requestTimeout.current);
                };
            }, []);

            const handleSearch = useCallback(
                value => {
                    setFetching(true);

                    clearTimeout(requestTimeout.current);
                    requestTimeout.current = setTimeout(async () => {
                        const result = await requestOptions({
                            axios,
                            dataSource: [],
                            page: INITIAL_PAGE,
                            searchValue: value,
                            requestSearch,
                            params,
                        });

                        setDataSource(result.dataSource);
                        setCanLoadMore(result.canLoadMore);
                        setPage(result.page);
                        setFetching(false);
                    }, 200);

                    setDataSource([]);
                    setCanLoadMore(false);
                    setPage(INITIAL_PAGE);
                    setSearchValue(value);
                },
                [requestSearch, params, axios],
            );

            const handleLoadMore = useCallback(async () => {
                setFetching(true);

                const nextPage = page + 1;

                const result = await requestOptions({
                    axios,
                    dataSource,
                    page: nextPage,
                    searchValue,
                    requestSearch,
                    params,
                });

                setDataSource(result.dataSource);
                setCanLoadMore(result.canLoadMore);
                setPage(result.page);
                setFetching(false);
            }, [searchValue, dataSource, page, requestSearch, params, axios]);

            const handleDropdownVisibleChange = useCallback(
                visible => {
                    if (onDropdownVisibleChange) onDropdownVisibleChange(visible);

                    if (visible && !dataSource.length) {
                        handleSearch('');
                    }
                },
                [onDropdownVisibleChange, dataSource, handleSearch],
            );

            const inputProps = useMemo(
                () => ({
                    ...input,
                    type: 'select',
                }),
                [input],
            );

            return (
                <Input
                    {...others}
                    onSearch={handleSearch}
                    options={dataSource}
                    input={inputProps}
                    loading={fetching}
                    showSearch
                    notFoundContent={<Empty description={false} />}
                    onLoadMoreClick={canLoadMore ? handleLoadMore : null}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    filterOption={false}
                />
            );
        },
    ),
);

InputSearch.Field = wrapFormField(InputSearch);

export default InputSearch;
