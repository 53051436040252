export default async (axios, searchValue, page, limit, params = {}) => {

    const response = await axios.get('/v1/accounts');

    const { data } = response;

    return {
        metadata: {
            page: 1,
            pages: 1,
        },
        result: data.map(account => {
            return {
                key: account.id,
                label: account.name,
            };
        }),
    };
};
