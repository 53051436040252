import React, { useCallback, useMemo } from 'react';

import { Image, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { STATUS_LISTING } from '~/domains/listing/AdTag';
import { MARKETPLACES_BY_ID } from '~/values/enums';

import makeURL from '../MakeUrl';
import styles from './AdDescriptionColumn.module.scss';

const TOOLTIP_OVERLAY = {
    maxWidth: '450px',
};
function AdDescriptionColumn({ adData, competitor = false }) {
    const { t } = useTranslation('AD_DESCRIPTION_COMPONENT');

    const {
        imageUrl,
        imageUri,
        title,
        accountName,
        marketplaceId,
        sku,
        skuMarketplace,
        competitorProductTitle,
        competitorImageUri,
        status,
        stock,
    } = adData;

    const hasAds = adData.hasAds || adData.activeInAds;

    const image = imageUrl || imageUri;

    const renderHasAdsIcon = useMemo(() => {
        if (hasAds === null && hasAds === undefined) return null;
        if (!hasAds) return null;

        return (
            <Tooltip title={t('IN_CAMPAIGN')} className={styles.purpleTag}>
                <Icon family="FontAwesome" name="bullhorn" size={14} />
            </Tooltip>
        );
    }, [t, hasAds]);

    const marketplaceDetails = useMemo(() => {
        const marketplaceName = MARKETPLACES_BY_ID.getItemByKey(marketplaceId)?.label || null;
        const statusName = STATUS_LISTING.find(statusListing => statusListing.key === status);
        const accountNameString = accountName ? ` | ${accountName}` : '';
        const statusLabelStyle = (statusName?.key === 'ARCHIVED' || statusName?.key === 'PAUSED')
            ? { color: 'red' }
            : {};

        return (
            <div>
                <>
                    {marketplaceName}
                    {accountNameString}
                    {statusName?.label && (
                        <>
                            {' | '}
                            <span style={statusLabelStyle}>{statusName.label}</span>
                        </>
                    )}
                </>
                {renderHasAdsIcon ? (
                    <>
                        {' | '}{renderHasAdsIcon}
                    </>
                ) : null}
            </div>
        );
    }, [marketplaceId, renderHasAdsIcon, status, accountName]);

    const renderImage = useMemo(() => {
        return (
            <div className={styles.productImageContainer}>
                <Image
                    width={60}
                    height={60}
                    src={competitor ? competitorImageUri : image}
                    preview={false}
                    fallback={require('~/assets/no-image.png').default}
                />
            </div>
        );
    }, [competitor, competitorImageUri, image]);

    const renderTooltip = useCallback(translation => {
        const conditionalHref = competitor ? adData.competitorLink : makeURL(title, adData);

        return (
            <Tooltip title={translation}>
                <a href={conditionalHref} target="_blank" rel="noreferrer">
                    <i className={styles.linkIcon}>
                        <Icon family="MaterialIcons" name="open_in_new" size={15} />
                    </i>
                </a>
            </Tooltip>
        );
    }, [competitor, adData, title]);

    const renderCompetitorAdDescription = useCallback(productTitle => {
        return (
            <>
                {productTitle} &nbsp;
                {renderTooltip(t('TOOLTIP_GO_TO_COMPETITOR_PRODUCT'))}
            </>
        );
    }, [t, renderTooltip]);

    const renderStock = useMemo(() => {
        if (stock === null && stock === undefined) {
            return t('NO_INFO');
        }

        return (
            <div>
                <Icon family="FontAwesome" name="boxes" size={12} />
                &nbsp;&nbsp;
                {stock}
            </div>
        );
    }, [t, stock]);

    return (
        <div className={styles.adDescriptionColumn}>
            {renderImage}
            {competitor ? (
                <div className={styles.competitorTitle}>
                    {renderCompetitorAdDescription(competitorProductTitle)}
                </div>
            ) : (
                <div className={styles.adDetails}>
                    <h6 className={styles.marketplaceDetails}>
                        {marketplaceDetails}
                    </h6>
                    <Tooltip
                        title={title.toLowerCase()}
                        overlayStyle={TOOLTIP_OVERLAY}
                    >
                        <h6 className={styles.adTitle}>
                            {title.toLowerCase()}
                            &nbsp;&nbsp;
                        </h6>
                    </Tooltip>
                    {renderTooltip(t('TOOLTIP_GO_TO_MARKETPLACE_LISTING'))}
                    <span className={styles.accountDetails}>
                        <p>
                            {t('MARKETPLACE_SKU')}: {skuMarketplace}
                        </p>
                        |
                        <p>
                            {t('SKU')}: {sku}
                        </p>
                        |
                        <Tooltip title={t('STOCK_TOOLTIP')}>
                            {renderStock}
                        </Tooltip>
                    </span>
                </div>
            )}
        </div>
    );
}
export default AdDescriptionColumn;
