import React, { lazy } from 'react';

import { Redirect } from 'react-router-dom';

import { MaterialIcons } from '~/components/Icon';
import PricingUpdateHistoryPage from '~/domains/pricingUpdateHistory/PricingUpdateHistoryPage';
import i18n from '~/translations';

import { getRoutesArray } from './manager';

const ChannelViewPage = lazy(() => import('~/domains/channel/ChannelViewPage'));
const IntegrationViewPage = lazy(() => import('~/domains/integrations/IntegrationViewPage'));
const AccountIntegrationAmazonViewPage = lazy(
    () => import('~/domains/accountIntegration/AccountIntegrationAmazonViewPage'),
);
const AccountIntegrationMeliViewPage = lazy(
    () => import('~/domains/accountIntegration/AccountIntegrationMeliViewPage'),
);
const AdListPage = lazy(() => import('~/domains/listing/AdListPage'));
const CampaignViewPage = lazy(() => import('~/domains/campaign/CampaignViewPage'));
const NewCampaignPage = lazy(() => import('~/domains/campaign/NewCampaignPage'));
const LinkerManager = lazy(() => import('~/domains/linkerManager/LinkerManager'));
const AnalysisAd = lazy(() => import('~/domains/ads/AnalysisAdPage'));
const RecommendationViewPage = lazy(() => import('~/domains/viewRecommendation/RecommendationViewPage'));
const BuyboxMonitoringPage = lazy(() => import('~/domains/buybox/BuyboxMonitoringPage'));
const NonBuyboxMonitoringPage = lazy(() => import('~/domains/nonBuybox/NonBuyboxMonitoringPage'));
const RecommendationFunilPage = lazy(() => import('~/domains/recommendationFunil/RecommendationFunilPage'));
// const RulesViewPage = lazy(() => import('~/domains/rules/RulesViewPage'));
// const CreateUpdatePricingRulesPage = lazy(() => import('~/domains/rules/CreateUpdatePricingRulesPage'));

const HOME_ROUTE = [
    {
        path: '/recommendation-funil',
        menuKey: '/home',
        component: RecommendationFunilPage,
        text: i18n.t('APP_ROUTES:HOME'),
        exact: true,
        noGroup: true,
        icon: {
            name: 'home',
            family: MaterialIcons,
        },
    },
];

const RECOMMENDATION_ROUTE = [
    {
        path: '/recommendation-view/:recommendation',
        menuKey: '/recommendation-view/:recommendation',
        component: RecommendationViewPage,
        exact: true,
    },
];

const ADS_ROUTES = [
    {
        group: i18n.t('APP_ROUTES:ADS_MANAGEMENT'),
        icon: {
            name: 'store',
            family: MaterialIcons,
        },
        routes: [
            {
                path: '/anuncios-sem-ads',
                menuKey: '/anuncios-sem-ads',
                component: AdListPage,
                text: i18n.t('APP_ROUTES:ADS_WITH_NO_ADS'),
                exact: true,
            },
            {
                path: '/gerenciador-vinculos',
                menuKey: '/gerenciador-vinculos',
                component: LinkerManager,
                text: i18n.t('APP_ROUTES:LINKER_MANAGER'),
                exact: true,
            },
            {
                path: '/campanhas',
                menuKey: '/campanhas',
                component: CampaignViewPage,
                text: i18n.t('APP_ROUTES:CAMPAIGNS'),
                exact: true,
            },
            {
                path: '/campanhas/nova',
                menuKey: '/campanhas',
                component: NewCampaignPage,
                exact: true,
            },
            {
                path: '/campanhas/:campaignId',
                menuKey: '/campanhas',
                component: NewCampaignPage,
                exact: true,
            },
            {
                path: '/indicadores',
                menuKey: '/indicadores',
                /* TODO: Correção de nomenclatura para Indicators ou IndicatorsPage? */
                component: AnalysisAd,
                text: i18n.t('APP_ROUTES:INDICATORS'),
                exact: true,
            },
            {
                path: '/recommendation-funil',
                menuKey: '/recommendation-funil',
                component: RecommendationFunilPage,
                text: i18n.t('APP_ROUTES:RECOMMENDATIONS'),
                exact: true,
            },
        ],
    },
];

const MONITORING_ROUTES = [
    {
        group: i18n.t('APP_ROUTES:RIVALS_MONITORING'),
        icon: {
            name: 'track_changes',
            family: MaterialIcons,
        },
        routes: [
            {
                path: '/monitoramento-buybox',
                menuKey: '/monitoramento-buybox',
                component: BuyboxMonitoringPage,
                text: i18n.t('APP_ROUTES:BUYBOX_MONITORING'),
                exact: true,
            },
            {
                path: '/monitoramento-fora-do-buybox',
                menuKey: '/monitoramento-fora-do-buybox',
                component: NonBuyboxMonitoringPage,
                text: i18n.t('APP_ROUTES:NON_BUYBOX_MONITORING'),
                // allowProduction: false,
                exact: true,
            },
        ],
    },
];

const PRICING_ROUTE = [
    {
        group: i18n.t('APP_ROUTES:PRICING'),
        icon: {
            name: 'monetization_on',
            family: MaterialIcons,
        },
        routes: [
            {
                path: '/historico-de-precos',
                menuKey: '/historico-de-precos',
                component: PricingUpdateHistoryPage,
                text: i18n.t('APP_ROUTES:UPDATE_HISTORY'),
                exact: true,
            },
        ],
    },
];

const SETTINGS_ROUTE = [
    {
        group: i18n.t('APP_ROUTES:SETTINGS'),
        icon: {
            name: 'settings',
            family: MaterialIcons,
        },
        routes: [
            {
                /* TODO: Correção de nomenclatura para AccountIntegrationPage? E do path? */
                path: '/channel',
                menuKey: '/channel',
                component: ChannelViewPage,
                text: i18n.t('APP_ROUTES:ACCOUNT_INTEGRATION'),
                exact: true,
            },
        ],
    },
];

const CONFIG_ROUTES = [
    {
        path: '/channel/amazon/integrations',
        component: AccountIntegrationAmazonViewPage,
        exact: true,
    },
    {
        path: '/channel/meli/integrations',
        component: AccountIntegrationMeliViewPage,
        exact: true,
    },
    {
        path: '/integration/:marketplace',
        menuKey: '/integration/:marketplace',
        component: IntegrationViewPage,
        exact: true,
    },
];

// const RULES_ROUTES = [
//     {
//         path: '/regras',
//         menuKey: '/regras',
//         component: RulesViewPage,
//         text: i18n.t('APP_ROUTES:RULES_LIST'),
//         exact: true,
//         noGroup: true,
//         allowProduction: false,
//         icon: {
//             name: 'list',
//             family: MaterialIcons,
//         },
//     },
//     {
//         path: '/regras/nova',
//         menuKey: '/regras',
//         component: CreateUpdatePricingRulesPage,
//         exact: true,
//     },
// ];

const HELP_ROUTE = [
    {
        path: 'https://treinamento.anymarket.com.br/course/index.php?categoryid=33/',
        menuKey: '/help',
        component: null,
        text: i18n.t('APP_ROUTES:HELP'),
        exact: true,
        noGroup: true,
        icon: {
            name: 'help',
            family: MaterialIcons,
        },
    },
];

const ROUTES = [
    {
        path: '/',
        component: () => <Redirect to="/app/recommendation-funil" />,
        exact: true,
    },
    ...HOME_ROUTE,
    ...RECOMMENDATION_ROUTE,
    ...ADS_ROUTES,
    ...MONITORING_ROUTES,
    ...PRICING_ROUTE,
    ...SETTINGS_ROUTE,
    ...CONFIG_ROUTES,
    // TODO - Adicionar quando finalizado
    // ...RULES_ROUTES,
    ...HELP_ROUTE,
];

export default ROUTES;

export const allRoutes = getRoutesArray({
    group: 'root',
    routes: ROUTES,
});
