import i18n from '~/translations';

export default async (axios, searchValue, page, limit, params = {}) => {

    const response = await axios.post('/v1/sellers/competitors', { searchValue });

    const { data } = response;

    data.unshift(data.pop());

    if (!data[0]) {
        return {
            metadata: {
                page: 1,
                pages: 1,
            },
            result: [],
        };
    }

    return {
        metadata: {
            page: 1,
            pages: 1,
        },
        result: data.map(seller => {
            if (!seller.rankingCompetitorName) {
                return {
                    key: seller.rankingCompetitorName,
                    label: i18n.t('VARIABLE_FILTER_LABELS:NO_COMPETITOR'),
                };
            }
            return {
                key: seller.rankingCompetitorName,
                label: seller.rankingCompetitorName,
            };
        }),
    };
};
