import moment from 'moment-timezone';

import i18n from '~/translations';

export function formatDate(str, format = i18n.t('DATE_HELPER:FORMAT_DATE')) {
    const date = moment.utc(str); // Ignores timezone since back-end it's dealing with already

    if (date.isValid() && format) {
        return date.format(format);
    }

    return null;
}

export function convertToMoment(val) {
    return !val || moment.isMoment(val)
        ? val
        : moment(val);
}

export function converterDate(date, locale = i18n.t('GLOBAL:LANGUAGE_LOCALE')) {
    const [ano, mes, dia] = date;
    const dataJavaScript = new Date(ano, mes - 1, dia);
    return new Intl.DateTimeFormat(locale, { month: '2-digit', day: '2-digit', year: 'numeric' })
        .format(dataJavaScript);
}
