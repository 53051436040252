/* eslint-disable max-len */
import React, { useCallback } from 'react';

import { Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { OUTSIDE_BUYBOX_STATUS } from '~/values/enums';

import styles from './OutsideBuyboxTagDescription.module.scss';

const OutsideBuyboxTagDescription = ({ data, openListingBuyboxDialog }) => {
    const { t } = useTranslation('BUYBOX_TAG_DESCRIPTION');

    const { rankingStatus } = data;

    const getIconColor = () => {
        switch (rankingStatus) {
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_WINNING:
                return styles.winner;
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_LOSING:
                return styles.loser;
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_NOT_COMPETING:
                return styles.outsideBuyBox;
            default:
                return styles.outsideBuyBox;
        }
    };

    const getTagClasses = () => {
        const classes = [styles.tag, getIconColor()];

        if (openListingBuyboxDialog) {
            classes.push(styles.click);
        }

        return classNames(classes);
    };

    const getTagText = () => {
        switch (rankingStatus) {
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_WINNING:
                return t('WINNING');
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_LOSING:
                return t('LOSING');
            case OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_NOT_COMPETING:
                return t('NOT_COMPETING');
            default: return t('NOT_COMPETING');
        }
    };

    const renderTooltip = () => {
        if (OUTSIDE_BUYBOX_STATUS.OUTSIDE_BUYBOX_NOT_COMPETING) {
            return t('NOT_MONITORING');
        }

        return null;
    };

    const handleClick = useCallback(() => {
        openListingBuyboxDialog(data);
    }, [data, openListingBuyboxDialog]);

    return (
        <Tooltip title={renderTooltip()}>
            <Tag
                onClick={openListingBuyboxDialog ? handleClick : null}
                className={getTagClasses()}
            >
                {getTagText()}
            </Tag>
        </Tooltip>
    );
};

export default OutsideBuyboxTagDescription;
